// react
import { useState, useContext, ChangeEvent } from 'react';

// types
import { Document, Transaction, UserRole } from '../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// context
import { ContextBanner, ContextUser } from '../../../App.context';

// hooks
import { useFetch } from '../../../hooks/useFetch';

// mui
import { Alert, Container, Grid, Skeleton, Typography } from '@mui/material';

// cards
import { CardTransaction } from '../../cards/CardTransaction/CardTransaction';

// dialogs
import { DialogTransaction } from '../../dialogs/DialogTransaction/DialogTransaction';

// ui
import { BannerProps } from '../Banner/Banner';

// props
export interface TransactionsProps {
	role: UserRole;
}

export const Transactions = ({ role }: TransactionsProps) => {
	// session
	const token = sessionStorage.getItem('token');

	// hooks
	const { t } = useTranslation();

	// state
	const [documents, setDocuments] = useState<Document[]>([]);
	const [isDialogTransactionOpen, setIsDialogTransactionOpen] = useState(false);
	const [transactions, setTransactions] = useState<Transaction[]>([]);
	const [selectedTransaction, setSelectedTransaction] = useState<Transaction>();

	// context
	const { setBanner } = useContext(ContextBanner);
	const { user } = useContext(ContextUser);

	// fetch transactions
	const { isLoading: isLoadingTransactions } = useFetch({
		isEnabled: Boolean(user),
		url: `${process.env.REACT_APP_API_URL}/api/GetBLDTTransactions/${user?.role}/${user?.scope}?populate=benefit,contract,expenseType,fromEntity`,
		options: {
			headers: {
				Authorization: `Bearer ${token}`,
				apikey: process.env.REACT_APP_APIKEY || '',
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setTransactions(res.data);
			}
		},
	});

	const transactionIds = transactions.map((t) => t.id);

	// fetch documents
	const { refetch: refetchDocuments } = useFetch({
		isEnabled: Boolean(user && transactionIds.length),
		url: `${process.env.REACT_APP_API_URL}/api/GetBlobs/${user?.role}/${
			user?.scope
		}?transaction=${transactionIds.join(',')}`,
		options: {
			headers: {
				Authorization: `Bearer ${token}`,
				apikey: process.env.REACT_APP_APIKEY || '',
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setDocuments(res.data);
			}
		},
	});

	const handleUpload = async (e: ChangeEvent<HTMLInputElement>, response: any) => {
		let banner: BannerProps = {
			hasClose: true,
			message: t('alert.fileUploaded'),
			color: 'success',
		};

		if (response.error) {
			banner = {
				hasClose: true,
				message: t('error.fileUploaded'),
				color: 'error',
			};
		}

		// refetch documents
		refetchDocuments();

		// set banner
		setBanner(banner);

		// close dialog
		setIsDialogTransactionOpen(false);
	};

	const handleClickTransaction = (transaction: Transaction) => {
		setSelectedTransaction(transaction);
		setIsDialogTransactionOpen(true);
	};

	const renderContent = () => {
		if (isLoadingTransactions) {
			return (
				<Grid item xs={12}>
					<Grid container spacing={2}>
						{[...Array(3)].map((a, i) => {
							return (
								<Grid item key={`skeleton-${i}`} xs={12}>
									<Skeleton height={140} variant="rounded" />
								</Grid>
							);
						})}
					</Grid>
				</Grid>
			);
		}

		if (transactions.length) {
			return transactions.map((transaction) => {
				// filter documents by transaction ID
				const transactionDocuments = documents.filter((doc) => doc.transaction === transaction.id);

				return (
					<Grid item key={`transaction-${transaction.id}`} xs={12}>
						<CardTransaction
							documents={transactionDocuments}
							onClick={() => handleClickTransaction(transaction)}
							role={role}
							transaction={transaction}
						/>
					</Grid>
				);
			});
		}

		return (
			<Grid item xs={12}>
				<Alert severity="info" variant="filled">
					{t('alert.empty')}
				</Alert>
			</Grid>
		);
	};

	return (
		<Container maxWidth={false}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography color="common.white" variant="h2">
						{role === 'BENEFICIARY' ? t('benefits') : t('payments')}
					</Typography>
				</Grid>
				{renderContent()}
			</Grid>
			<DialogTransaction
				isOpen={isDialogTransactionOpen}
				onClose={() => setIsDialogTransactionOpen(false)}
				onUpload={handleUpload}
				transaction={selectedTransaction}
			/>
		</Container>
	);
};
