// types
import { TransactionType } from '../@types';

// i18
import { TFunction } from 'i18next';

export const translateTransactionType = (type: TransactionType, t: TFunction) => {
	const types: Record<TransactionType, string> = {
		CASH_BENEFIT: t('cashBenefit'),
		INITIAL: t('initialDeposit'),
		OPEX: t('operatingExpense'),
		NON_CASH_BENEFIT: t('nonCashBenefit'),
		PAYMENT: t('payment'),
		REPLENISHMENT: t('replenishment'),
		TRANSFER: t('transfer'),
	};

	return types[type] || type;
};
